.briefcase {
    padding: 0;
    position: relative;

    &__section {
        padding: 64px 0;
        padding-top: 120px;
        
        &--feedback {
            padding: 64px 0;
            padding-top: 120px !important;
            padding-bottom: 180px !important;

            &-cards {
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding: 0 24px;
                padding-left: 0;
                max-height: 400px;
                overflow-y: scroll;

                @include breakpoint(lg) {
                    max-height: 600px;
                }

            }

            &-card {
                background-color: $neutralLighterAlt;
                color: $white;
                padding: 18px 24px;
                padding-right: 48px;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 100%;
                min-width: 100%;
                position: relative;

                @include breakpoint(lg) {
                    max-width: 100%;
                    min-width: 50%;
                }

                @include breakpoint(xl) {
                    max-width: 100%;
                    min-width: 50%;
                }
                
                &-flag {
                    position: absolute;
                    right: -24px;
                    border-radius: 4px;
                    z-index: 2;
                    width: 48px;
                    height: 28px;
                }
                
                &-fixed-flag {
                    width: 48px;
                    height: 28px;
                    border-radius: 4px;
                }

                &-avatar {
                    background-color: $primary;
                    color: $secondary;
                    border: solid 1.4px $secondary;
                    font-weight: 600;
                }

                &-title {
                    color: $primary !important;
                    font-weight: 600;
                    font-size: 22px;
                }

                &-description {
                    font-size: 16px;
                    display: block;
                    color: $black !important;
                    text-overflow: ellipsis;
                    overflow: hidden !important;
                    white-space: nowrap;
                    max-width: 100%;
                }

                &-date {
                    color: $neutralSecondaryAlt;
                }
            }

            &-modal {
                &-title {
                    color: $primary !important;
                    margin-bottom: 0 !important;
                }
                &-description {
                    font-size: 16px;
                    color: $black !important;
                }
            }

            &-select {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 24px;
                padding: 12px 24px;
                border-radius: 8px;
                width: 100%;
                min-height: 600px;
                margin: 0 auto;
                position: relative;
                overflow: hidden;

                span {
                    color: $black;
                    font-size: 18px;
                    font-weight: 400;
                }

                h4 { margin-bottom: 0; }

                @include breakpoint(md) { width: 600px; }

                &-avatar {
                    margin: 0 auto;
                    display: block;
                    align-items: center;
                }

                &-flag {
                    bottom: -24px;
                    right: -24px;
                    position: absolute;
                    opacity: .08;
                    z-index: 2;
                    filter: grayscale(50%);
                }
            }
        }
    }


    &__title {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        padding: 12px 0;
        font-weight: 700 !important;
        border-radius: 8px;
        background: linear-gradient(268.74deg, #E6E6E6 -5.85%, rgba(245, 245, 245, 0) 107.54%);
    }

    &__portrait {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 24px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        
        @include breakpoint(md) {
            height: 400px;
            margin-bottom: 64px;
            
        }

        &--title {
            z-index: 9;
            position: relative;
            padding: 24px 64px;
        }

        &--position {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            &.mask {
                background-color: $lucesPrimary;
                opacity: 1;
                z-index: 3;

            }

            &.image {
                z-index: 2;
                background-image: url('https://firebasestorage.googleapis.com/v0/b/standarrector/o/library%2Fbackground.jpg?alt=media&token=b449e76d-a87b-4c87-8705-ffdc2957e025');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                opacity: .4;
            }
        }
    }

    &__items {
        padding: 0;
        height: 100%;

        &--item {
            width: 100%;
            position: relative;
            border-radius: 8px;
            border: solid 1.4px $neutralLighterAlt;
            position: relative;
            
            &-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }

            &-mask {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background-color: $primaryLight;
                opacity: .4;
                z-index: 2;
            }

            &-title {
                margin-bottom: 0 !important;
                font-size: 20px !important;
                font-weight: 600 !important;
                color: $lucesPrimary !important;
                text-align: left;
                width: 100%;
                padding: 8px 16px;
                border-radius: 8px;
                background: $neutralLight;
            }
        }
    }
}