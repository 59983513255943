@import '../../styles/variables';
@import '../../styles/utils';

.textBold {
    font-size: 18px;
    font-weight: bold;
    color: $neutralDark;    
}
.centerLogo {
    padding-top: 48px;
    padding-left: 48px;  
}

.contactLogo {
    width: 350px;
}

.contact {
    position: relative;
    padding: 48px 0;
    z-index: 2;    

  
    // .message {
    //     font-size: 24px;
    //     font-weight: 500;
    //     color: $neutralDark;
    //     display: block;
    //     text-align: center;
    
    //     padding: 12px 16px;
    //     border-radius: 8px;
    //     margin: 0 auto;
    //     background-color: $neutralLighter;
    //     width: 100%;
    // }

    // .contMessage {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    // }

    // .cloudText {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     width: 100%;
    //     height: 450px;
    //     position: relative;

    //     .largeProps {
    //         color: $primary;
    //         display: block;
    //     }

    //     .mediumProps {
    //         color: $primary;
    //         font-weight: 500 !important;
    //         display: block;

    //     }

    //     .smallProps {
    //         color: $primary;
    //         font-size: 18px !important;
    //         font-weight: 400 !important;
    //         display: block;
    //     }
    // }
}