@import '../../styles/variables';
@import '../../styles/utils';

.contact {
    margin: 16px 0;
    margin-top: 20px;
    background-color: $neutralLighter;
    border-radius: 8px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    
    @include breakpoint (md) {
        flex-direction: row;
        align-items: center;
    }
}

.containerIcon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;

    &:last-child { margin-bottom: 0; }

    .text {
        font-size: 16px;
        font-weight: 500;
        color: $primary;
        display: flex;
        text-align: left;
    }
}


.socialMedia {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    .icon {
        margin: 0 8px;
        cursor: pointer;
        &:first-child { margin-left: 0; }
        &:last-child { margin-right: 0; }
    
        &:active { transform: scale(0.85); }
    }

    @include breakpoint (md) {
        justify-content: flex-end;
    }
}

.socialIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    vertical-align: middle;
    margin: 0;

    fill: $primary;
    height: 28px;
    width: 28px;

}