.portrait {
    height: 100%;
    width: 100%;
    position: relative;
    /* padding: 0px 0 100px 0; */
    padding: 40px 0 100px 0;

    &__text-container {
        background-color: $light;
        padding: 24px;
        border-radius: 4px;
        max-width: 80%;
        position: relative;
        margin: 0 auto;
    }
    
    &__line {
        width: 14%;
        height: 8px;
        border-radius: 8px;
        background-color: $lucesSecondary;
        margin-bottom: 8px;
    }

    &__text {
        &-luces {
            color: $lucesPrimary !important;
            margin: 0 !important;
            font-size: 72px !important;
            font-weight: 800 !important;
        }

        &-description {
            font-size: 32px;
            font-weight: 400;
        }

        &-slogan {
            color: $lucesSecondary;
            font-weight: 800;
            font-size: 32px;
            text-transform: uppercase;
            padding: 8px 16px;
            padding-left: 0;
            border-radius: 8px;
            width: fit-content;
            background: linear-gradient(268.74deg, #E6E6E6 -5.85%, rgba(245, 245, 245, 0) 107.54%);
        }
    }

    &__image {
        display: none;
        margin: 0 auto;
        padding: 0 32px;
        @include breakpoint(lg) { display: block; padding: 0; }
    }

    @include breakpoint(sm) { 
        height: 80vh;
        padding: 0;
    }

}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &-left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        object-fit: none;
    }

    &-right {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        object-fit: none;
    }
}

.section {
    position: relative;
    z-index: 2;
    
    &__training {
        padding: 48px 0;
        height: 100%;

        &--information {

            &-container {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                max-height: 100%;

                @include breakpoint(xl) {
                    flex-direction: column;
                    justify-content: center;
                    max-height: 600px;
                }

                .filter {
                    background-color: $tertiary;
                    height: 100%;
                    left: 0;
                    mix-blend-mode: multiply;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 2;
                }
            }

            &-text {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 40px;
                max-height: calc(100% - 56px);
                background-color: $lighterAlt;
                overflow-y: scroll;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;

                @include breakpoint(xl) {
                    padding: 28px 40px;
                    max-height: 600px;
                }
            }

            &-line {
                height: 6px;
                border-radius: 8px;
                background-color: $primary;
                margin-bottom: 24px;
                margin-top: -8px;
                width: 10%;
            }

            &-item {
                margin-bottom: 24px;
                font-size: 20px;
            }
        }
    }

    &__wtd {
        padding: 48px 0;

        &--items {
            padding: 28px 0;
            width: 100%;
            height: 100%;
        }

        &--item {
            background-color: $light;
            width: 100%;
            height: fit-content;
            padding: 12px 24px;
            border-radius: 8px;

            justify-content: flex-start;

            @include breakpoint(md) {
                justify-content: center;
                height: 320px;
            }

            @include breakpoint(xxl) {
                justify-content: center;
                height: 300px;
            }

            &-label {
                margin-bottom: 16px;

                &-title {
                    font-weight: 700 !important;
                }

                &-icon {
                    background-color: $primary;
                    border-radius: 4px;

                    svg {
                        color: $white;
                        font-size: 32px;
                    }
                }
            }

            &-description {
                font-size: 18px;
            }
        }
    }
}