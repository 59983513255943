.foundations {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__transversalities {
        position: relative;

        &--image {
            width: 100%;
            top: 0;
            right: 0;
            height: 100%;
            position: absolute;
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: cover;
            opacity: .1;
            filter: grayscale(100%);

            @include breakpoint(md) {
                width: 40%;
                background-size: contain;
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &--items {
            height: 100%;
            position: relative;
            box-sizing: border-box;

            &-item {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;

                &-title {
                    background-color: $primary;
                    padding: 8px 16px;
                    border-radius: 8px;
                    width: fit-content;

                    h3 {
                        color: $white !important;
                        margin-bottom: 0;
                    }
                }

                &-description {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-top: 12px;
                    padding: 12px;
                    padding-left: 0;
                    border-radius: 4px;
                    overflow-y: scroll;
                    margin-bottom: 24px;
                }

                &-text {
                    font-size: 16px;
                    margin-bottom: 8px;

                    @include breakpoint(lg) {
                        font-size: 18px;
                    }
                }

            }
        }
    }

    &__section {
        position: relative;
        
        &--collective {
            background-color: $neutralLighterAlt;
            padding: 48px 24px;
            margin: 48px 0;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 24px;
            border-radius: 4px;

            &-text {
                font-size: 18px;
            }
        }

        &--profile {
            background-color: $primary;
            padding: 64px 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 48px;
            gap: 16px;
            width: 100%;

            span {
                color: $white;
                text-align: center;
                font-size: 24px;
                width: 100%;
                display: block;
            }
        }
    }

    &__competencies {
        &--item {
            background-color: $neutralLighterAlt;
            border-radius: 8px;
            padding: 16px 24px;
        }
    }
}

.sense {
    padding: 100px 0;
    position: relative;
    z-index: 2;

    &__card {
        border-radius: 8px;
        border: solid 2px $primary;
        padding: 16px 24px;
        background-color: $white;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include breakpoint(lg) {
            flex-direction: row;
        }

        &--icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $primary;
            margin: 0;
            margin-bottom: 8px;

            @include breakpoint(lg) {
                margin: 0;
                margin-right: 8px;
                flex-direction: row;
            }
        }
    }
}

.team {
    width: 100%;
    padding: 24px 0;

    &__directive {

        &--info {
            padding: 12px 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
        } 

        &--image {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primary;
        }
    }
}