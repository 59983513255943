.cloister {
    margin-left: auto;
    margin-right: auto;
    max-width: 1248px;
    padding: 0 42px;

    &__header {
        width: 100%;
        height: 320px;
        margin-bottom: 24px;
        padding: 40px 24px;
        border-radius: 4px;
        position: relative;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        
        display: flex;
        justify-content: center;
        align-items: flex-end;
        
        &::before {
            border-radius: 4px;
            background: linear-gradient(transparent, #111c2a);
            bottom: 0;
            content: "";
            height: 72%;
            left: 0;
            position: absolute;
            transition: all .15s;
            width: 100%;
            z-index: -1;
        }

        h2 {
            text-align: center;
            color: $white;
        }
    }

    &__cards {
        width: 100%;
        margin: 50px -6px !important;
        position: relative;

        &--card {
            border-radius: 4px;
            box-shadow: 0 9px 10px rgba(0,0,0,.251);
            display: block;
            height: 100px;
            overflow: hidden;
            padding-top: 118.8%;
            position: relative;
            transition: all .15s;
            z-index: 0;
            text-decoration: none;

            &::before {
                background: linear-gradient(transparent, #111c2a);
                bottom: 0;
                content: "";
                height: 72%;
                left: 0;
                position: absolute;
                transition: all .15s;
                width: 100%;
                z-index: -1;
            }

            &:hover {
                box-shadow: 0 9px 10px rgba(0,0,0,.26);
                transform: translateY(-20px);
            }

            img {
                height: 100%;
                left: 0;
                object-fit: cover;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: -2;
            }

            &-text {
                padding: 0 27px 20px;
                bottom: 0;
                left: 0;
                position: absolute;
                text-align: center;
                width: 100%;
                z-index: 3;
                
                h5, span {
                    color: $white;
                    text-align: left;
                    margin: 0;
                }

                &-job {
                    text-align: left;
                    font-size: 14px;
                    display: flex;

                }
            }
        }
    }

    &__button {
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}