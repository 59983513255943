.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary;
}

.ant-tabs {
    width: 100%;
    
    &-ink-bar {
        background-color: $primary;
    }

    &-tab {
        font-weight: 500;
        font-size: 20px;

        &:hover {
            color: $primary;
        }
    }
}