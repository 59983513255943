.experiences {
    padding: 24px 0;
    min-height: 600px;
    
    &-members {
        width: 100%;
        min-height: fit-content;
        height: auto;
        max-height: 350px;
        overflow-y: scroll;
    }

    &-links {
        width: 100%;
        min-height: fit-content;
        height: auto;
        max-height: 250px;
        overflow-y: scroll;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 12px;
    }

    &-description {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0 !important;
    }

    &-empty {
        width: 100%;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-loading {
        width: 100%;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
    }

    &-footer {
        background-color: rgba(218, 218, 218, 0.08);
        padding: 24px;
        border-radius: 8px;
        margin-top: 16px;
    }

    &-pagination {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;

        @include breakpoint(md) {
            justify-content: flex-end;
        }
    }

    &-avatar {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}