
.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 48px;
  
    @include breakpoint(md) { padding: 40px 24px; }
    @include breakpoint(lg) { padding: 40px 32px; }
    @include breakpoint(xl) { padding: 40px 64px; }
    @include breakpoint(xxl) { padding: 40px 128px; }
  
  
    &__logo {
      width: 85px;
  
      @include breakpoint(md) { width: 250px; }
  
    }
  
    &__menu {
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding: 8px 16px;
      display: none;
      gap: 24px;
      
      @include breakpoint(lg) { display: flex; }
  
      a { text-decoration: none; }
  
      .option {
        margin: 0 8px;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
  
        &:hover { background-color: $neutralLighterAlt; }
  
        span {
          color: $primary;
          font-weight: 600;
        }
  
        &.active {
          background-color: $primary;
          span { color: $white; }
        }
        
      }
    }
  
    &__icon {
      display: flex;
      font-size: 32px;
      color: $primary;
      
      @include breakpoint(lg) { display: none; }
    }
  
    &__language {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }