.card {
    background-color: $neutralLighterAlt;
    border: solid 1.4px $neutralLight;
    padding: 12px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    
    &-flag {
        position: absolute;
        right: -24px;
        border-radius: 4px;
        z-index: 2;
        width: 48px;
        height: 28px;
    }
    
    &__fixed--flag {
        width: 24px;
        height: 16px;
        border-radius: 2px;
    }

    &__avatar {
        background-color: $neutralLighter;
        color: $primary;
        border: solid 1.4px $primary;
        font-weight: 600;
        font-size: 14px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__title {
        color: $primary !important;
        font-weight: 600;
        font-size: 22px;
    }

    &__description {
        font-size: 14px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
    }
}