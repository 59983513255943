@import '~antd/dist/antd.css';
@import './aligns';
@import './utils';
@import './variables';
@import './components/index.scss';
@import './screens/index.scss';
@import './widgets/index.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
        -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

* { box-sizing: border-box; }

.none {
  &__margin {
      margin: 0 !important;

      &--top { margin-top: 0 !important; }
      &--bottom { margin-bottom: 0 !important; }
      &--left { margin-left: 0 !important; }
      &--right { margin-right: 0 !important; }

      &--horizontal { margin-left: 0 !important; margin-right: 0 !important; }
      &--vertical { margin-top: 0 !important; margin-bottom: 0 !important; }

      &--all { margin: 0 !important; }

      &--small { margin: 8px !important; }
      &--medium { margin: 16px !important; }
      &--large { margin: 24px !important; }
  }

  &__padding {
      padding: 0 !important;

      &--top { padding-top: 0 !important; }
      &--bottom { padding-bottom: 0 !important; }
      &--left { padding-left: 0 !important; }
      &--right { padding-right: 0 !important; }

      &--horizontal { padding-left: 0 !important; padding-right: 0 !important; }
      &--vertical { padding-top: 0 !important; padding-bottom: 0 !important; }

      &--all { padding: 0 !important; }

      &--small { padding: 8px !important; }
      &--medium { padding: 16px !important; }
      &--large { padding: 24px !important; }
  }

  &__border { border: none !important; }
}

.full {
  &__height { height: 100%; }
  &__width { width: 100%; }
  &__size { width: 100%; height: 100%; }
}

.responsive {
  display: none;
  @include breakpoint(lg) { display: flex; }
}