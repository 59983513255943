.calendar {
    width: 100%;
    position: relative;

    &__container {
        position: relative;
        height: fit-content;

        &--events {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            overflow-x: scroll;
            padding: 24px 0;
        }
    }

    &__events {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 16px;
        width: 250px;
        min-width: 220px;
        position: relative;

        &.first { margin-left: 0 }
        &.last { margin-right: 0 }

        &--month {
            height: 100%;
            margin-bottom: 16px;
            width: 100%;
            text-align: center;
            padding: 4px 0;
            border-radius: 4px;
            background-color: $neutralLight;
        }

        &--list {
            width: 100%;
            padding: 4px;
            border-radius: 4px;

            &-item {
                padding: 6px 8px;
                border-radius: 4px;
                border: solid 1px $primary;
                background-color: $white;
                cursor: pointer;

                &-region {
                    font-size: 14px !important;
                }

                &-date {
                    font-size: 12px !important;
                }
            }
        }
    }
}