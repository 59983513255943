@import '../../styles/variables';
@import '../../styles/utils';

.modal {
    width: 90% !important;
    font-size: 16px;
    @include breakpoint(md) { width: 1100px !important; }

    .body {
        margin-top: 12px;
        padding: 24px 0;
        padding-top: 8px;
    }
}

.containerForm {
    padding: 24px 0;
    height: 800px;
    overflow-y: scroll;
}