.course {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    width: 100%;

    &__header {
        display: flex;
        position: relative;
        min-height: 350px;
        width: 100%;
        padding: 30px 0;
        border-radius: 4px;
        padding-bottom:60px;
        margin-bottom: 45px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        mix-blend-mode: multiply;

        &--effect {
            position: absolute;
            border-radius: 4px;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: $light;
            opacity: .9;
        }

        &--container {
            padding: 0 12px;
            margin: 0 auto;
            width: 100%;
            position: relative;
            z-index: 3;
        }

        &--logo {
            z-index: 3;
            background-color: $white;
            border-radius: 50%;
            padding: 12px;
            position: absolute;
            bottom: -40px;
            left: 80px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint(xl) {
                bottom: -40px;
                left: 80px;
            }

            img {
                object-fit: contain;
                width: 85%;
                height: 85%;
                margin: 0 auto;
                display: flex;
            }
        }
    }
}