.programs {
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #f8fbff;
    border-radius: 4px;

    &__program {
        margin-bottom: 16px;
        height: 210px;
        padding: 0 12px;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        background: #c7e0f4;
        border-radius: 4px;
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        &.l {
            span {
                color: $primary !important;
            }
        }
    } 
}
