.ant-page-header {
    padding: 0;
    padding-bottom: 24px;
    width: 100%;
    
    &-heading-title {
        color: $primary !important;
        font-size: 28px !important; 
    }

    &-content {
        padding-top: 4px;
    }

    &-footer {
        margin-top: 24px;
    }
}