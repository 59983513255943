@import '../../styles/utils';
@import '../../styles/variables';

.footer {
  background-color: $dark;
  padding: 8px 0;
  width: 100%;
  
  .line {
    background-color: $white;
    height: 1px;
    margin: 8px 0;
    width: 100%;
  }

  .info {
    padding: 8px 48px;
    width: 100%;

    @include breakpoint(md) { padding: 8px 24px; }
    @include breakpoint(lg) { padding: 8px 32px; }
    @include breakpoint(xl) { padding: 8px 64px; }
    @include breakpoint(xxl) { padding: 8px 128px; }
  }

  .copyright {
    display: none;
    @include breakpoint(md) { display: flex; }
  }
  
  .socialIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;

    &:last-child { margin-right: 0; }
    &:first-child { margin-left: 32px; }

    svg {
      fill: $white;
      height: 24px;
      width: 24px;
    }
  }
}