.ant-typography {
    font-size: 16px;
    margin-bottom: 0;

    &.center { text-align: center; }

    @include breakpoint(md) {
        font-size: 17px;
    }
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
    color: $primary;

    &.center { text-align: center; }
}