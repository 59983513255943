.item {
    &--text {
        font-size: 14px;
        font-weight: 500;

        &-value {
            font-weight: 500;

            &.link {
                color: $primary;
                cursor: pointer;
            }
        }
    }

    &__summary {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;

        &--text {
            font-size: 14px;
            font-weight: 500;

            &-label {
                font-size: 12px;
                color: $neutralSecondary;
            }

            &-value {
                color: $black;
                font-weight: 500;

                &.link {
                    color: $primary;
                    cursor: pointer;
                }

                &.info {
                    font-size: 12px;
                }
            }
        }

    }
}