.lifelong {
    padding-bottom: 120px;
}

.timeline {
    width: 100%;
    height: 100%;
    padding: 24px 0;

    @include breakpoint(lg) {
        width: 800px;
    }

    &__container {
        border-radius: 4px;
        width: 100%;
        padding: 24px;
        border: solid 1px $neutralLight;
        background-color: $neutralLighterAlt;
    }

    &__card {
        background-color: #1B4484;
        border-radius: 4px;
        min-height: 250px;

        p, span, h5 {

            color: $white;
        }

    }
}

.box {
    margin-bottom: 14px;
    width: 100%;
    padding: 24px;

    &__circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $primary;
    }
    &__line {
        width: 4px;
        border-radius: 8px;
        height: 120px;
        background-color: $primary;
        margin: 8px auto;
    }

    &__info {
        box-shadow: 2px 2px 4px 0px #CCC;
        border-radius: 4px;
        background-color: $white;
        width: 100%;
        height: 70%;
        padding: 12px;
        overflow-y: scroll;
    }

    &__lighthouse {
        height: 70%;

        &--title {
            background-color: #1B4484;
            padding: 4px 12px;
            width: 100%;
            text-align: center;

            &.yellow {
                background-color: #FBDD71;

                h4 {
                    color: $dark;
                }
            }
            &.orange {
                background-color: #F3972F;
                h4 {
                    color: $dark;
                }
            }
            &.green {
                background-color: #99CC33;
                h4 {
                    color: $dark;
                }
            }


            h4 {
                color: $white;
                margin: 0;
            }

        }
    }
}