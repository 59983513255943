@import '../../styles/utils.scss';

* { box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
}

.layout {
  margin: 0;
  padding: 24px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 56px !important;

  @include breakpoint(md) { padding: 0px 24px; }
  @include breakpoint(lg) { padding: 0px 32px; }
  @include breakpoint(xl) { padding: 0px 64px; }
  @include breakpoint(xxl) { padding: 0px 128px; }
}