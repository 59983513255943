.ant-menu {
    &-item {
        font-weight: 500;
        font-size: 16px;
        color: $primary;

        @include breakpoint(lg) {
            font-size: 18px;
        }
        
        &:after {
            display: none !important;
        }
        &-selected {
            font-weight: 600;
            color: $white !important;
            background-color: $primary !important;

            a {
                color: $primary !important;
            }
        }

        &:active {
            background-color: $neutralLighter !important;
        }

        &:hover {
            color: $primary !important;
            background-color: $neutralLighter !important;

            a {
                color: $primary !important;
            }
        }
    }

    &-submenu {
        &-title {
            font-size: 16px;
            color: $primary;

            @include breakpoint(lg) {
                font-size: 18px;
            }
        }
    }

    &-horizontal {
        border-bottom: 0 !important;
        &:not(.ant-menu-dark) {
            & > .ant-menu-item-selected::after {
                border-bottom: 2px solid $primary !important;
            }
        }
    }
}


.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
    color: $primary !important;
    
    a {
        color: $primary !important;
    }

    &::after {
        border-bottom: 2px solid $primary !important;
    }
}

.ant-menu-light .ant-menu-submenu-title:hover {
    color: $primary !important;
} 