.tag {
    background-color: $white;
    border-radius: 4px;
    color: $primary;
    padding: 1px 6px;
    font-weight: 600;
    font-size: 14px;

    display: flex;
    width: fit-content;
}