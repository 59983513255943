.sponsors {
    padding: 120px 0;
    height: 100%;
    gap: 120px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    
}