.enrollment {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
    border-top: 12px solid $primary;
    position: relative;
    padding: 24px;
    margin-bottom: 32px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}