.ant-table {
    border-radius: 8px;

    &-thead {
        > tr {
            > th {
                background-color: $primary;
                color: $white;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.05px;
                padding: 8px;
            }
        }
    }

    &-tbody {
        > tr {
            > td {
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0.05px;
            }
        }
    }
}

.table {

    &__summary, &__title {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__help {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    &__category {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 6px;
        padding: 4px 12px;
        background-color: $lighter;
        color: $primary;
        width: fit-content;
        font-weight: 600;
        margin: 0 auto;
    }

    &__external-link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        background-color: $neutralLighter;
        color: $primary;
        border-radius: 6px;
        padding: 4px 12px;
        width: fit-content;
        margin: 0 auto;
        cursor: pointer;

        &-icon {
            color: $primary;
            vertical-align: text-bottom
        }
    }

    &__not-available {
        border: solid 1.4px $neutralTertiary;
        padding: 4px 12px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        margin: 0 auto;

        &.ACTIVE {
            border-color: $primary;
            color: $primary;
        }

        &.FINISHED {
            border-color: $green;
            color: $green;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        &-icon {
            border-radius: 4px;
            padding: 4px;
            background-color: $lighter;
            color: $primary;

            &.delete {
                color: $red;
                background-color: $orangeLight;

                &:hover {
                    background-color: $orangeLight;
                }
            }

            &:hover {
                cursor: pointer;
                background-color: $lighterAlt;
            }

            &:active {
                transform: scale(0.96);
            }
        }
    }
}