.ant-row {
    width: 100%;
    margin: 0 auto !important;
}

.ant-col {
    &.col-left {
        padding: 0 !important;

        @include breakpoint(xl) {
            padding-right: 16px !important;
        }
    }

    &.col-right {
        padding: 0 !important;

        @include breakpoint(xl) {
            padding-left: 16px !important;
        }
    }
    
}

.ant-space-align-center {
    width: 100%;
}

.layout {
    height: 100vh;
    margin: 0;
    min-height: 100vh;
    overflow: hidden;
    width: 100%;
}

.wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 64px 0;
    overflow-y: scroll;

    &__signup {
        justify-content: flex-start;
        @include breakpoint(lg) {
            justify-content: flex-start;
        }
        @include breakpoint(xl) {
            justify-content: flex-start;
        }
    }
}

.forms {
    &__main {
        width: 100%;
        padding: 0 28px;
        border-radius: 4px;

        @include breakpoint(md) { width: 80%; }
        @include breakpoint(lg) {
            width: 100%;
            padding: 0 28px;
        }
        @include breakpoint(xl) { width: 90%; }
        @include breakpoint(xxl) { width: 70%; }
    }
}

.sliders {
    width: 100%;
    height: 100%;
    background: linear-gradient(28.61deg, #003166 16.12%, #1B4484 123.7%);
}

.link { cursor: pointer; }

.or {
    margin: 32px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .line {
        background-color: $neutralTertiaryAlt;
        height: 2px;
        width: 75px;
        margin: 0 8px;
        margin-top: 4px;
        display: block;
    }
    
    .text {
        color: $neutralTertiary;
        font-weight: 600;
        font-size: 16px;
    }
}

.dont-has-account {
    margin: 0 auto;
    margin-top: 32px;
    width: 100%;
    justify-content: center;
}


.welcome-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 48px;
}

.copyright {
    margin-top: 12px;
    color: $white;
}